import React, { useState, useEffect, useContext, useRef } from 'react';
import Select from 'react-select';
import CryptoJS from 'crypto-js';
import { toastError } from 'utils/toasts';
import { useLocation, useNavigate } from 'react-router-dom';
import { FiSearch } from 'react-icons/fi';
import { MdChevronLeft, MdChevronRight, MdInfo } from 'react-icons/md';
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
  useExpanded,
} from 'react-table';
import { categoryOptions } from 'constants/categories';
import { countryOptions } from 'constants/countries';
import TooltipHorizon from 'components/tooltip';
import { CreditsContext } from 'contexts/CreditsContext';
import { IoEyeOffOutline, IoEyeOutline } from 'react-icons/io5';
import { put, get, getUserId } from 'api';
import Spinner from 'components/loaders/Spinner';
import { referralUrls } from 'constants/marketplaces';
import InfoModal from 'components/info-modal/InfoModal';

const nichesOptions = [
  { value: '', label: 'General' },
  { value: 'Gambling', label: 'iGaming' },
  { value: 'Adult', label: 'Adult' },
  { value: 'CBD', label: 'CBD' },
];

const styles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? '#f5f5f5'
        : isFocused
        ? '#ccc'
        : null,
      color: isDisabled ? '#ccc' : '#4B5563',
      cursor: isDisabled ? 'not-allowed' : 'pointer',
      fontSize: '14px',
      lineHeight: '20px',
      fontFamily: 'DM Sans',
      fontWeight: '500',
      '&:active': {
        backgroundColor: '#f5f5f5',
      },
    };
  },
};

function SearchByDomain(props) {
  const { columns } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const [hasUserTyped, setHasUserTyped] = useState(false);
  const [domainName, setDomainName] = useState(null);
  const { currentBalance, refreshBalance } = useContext(CreditsContext);
  const [searchTerm, setSearchTerm] = useState('');
  const [categories, setCategories] = useState(null);
  const [countries, setCountries] = useState(null);
  const [allowedNiche, setAllowedNiche] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [data, setData] = useState([]);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const userId = getUserId();
  const tableRef = useRef();
  const [dataLoading, setDataLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (location.state?.domainName) {
      setDomainName(location.state.domainName);
      location.state = null;
    } else {
      const queryParams = new URLSearchParams(location.search);
      const domain = queryParams.get('domain');
      if (domain) {
        setDomainName(domain);
      }
    }
  }, [location]);

  useEffect(() => {
    if (hasUserTyped) {
      const queryParams = new URLSearchParams(location.search);
      queryParams.delete('domain');
      const newQueryString = queryParams.toString();
      window.history.replaceState(
        {},
        '',
        `${location.pathname}${newQueryString ? `?${newQueryString}` : ''}`,
      );
    }
  }, [hasUserTyped]);

  const handleViewMore = async (e) => {
    e.preventDefault();
    const checkSearchLimit = Number(currentBalance) <= 0 ? true : false;
    await put(
      `/api/domains/expansion-charge-credits?userId=${userId}&checkSearchLimit=${checkSearchLimit}`,
    );
    refreshBalance();
  };

  const handleSearch = async (e) => {
    setDataLoading(true);
    if (e) e.preventDefault();
    if (
      searchTerm ||
      (categories && categories.length > 0) ||
      (countries && countries.length > 0)
    ) {
      const categoryValues = categories
        ?.map((category) => category.label)
        .join(',');
      const countryValues = countries
        ?.map((country) => country.label)
        .join(',');
      const nicheValue = allowedNiche ? allowedNiche.value : null;
      const checkSearchLimit = Number(currentBalance) <= 0 ? true : false;
      await get(
        `/api/domains/exact-domain-search?userId=${userId}&categories=${categoryValues}&countries=${countryValues}&searchTerm=${searchTerm}&page=${currentPage}&limit=${pageSize}&checkSearchLimit=${checkSearchLimit}&allowedNiche=${nicheValue}`,
      )
        .then((res) => {
          if (res.status === 200) {
            return res.json();
          } else if (res.status === 429) {
            toastError(
              'You have reached the free tier limit of 10 searches per day, please subscribe to a plan to continue.',
              3500,
            );
            return null;
          }
        })
        .then((data) => {
          if (data) {
            const encryptedData = data.data;
            const bytes = CryptoJS.AES.decrypt(
              encryptedData,
              process.env.REACT_APP_CRYPTO_SECRET,
            );
            const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

            if (!decryptedData) {
              return;
            }
            setData(decryptedData);
            setTotalDataCount(data.total);
            if (data.total === 0) {
              toastError(
                "Unfortunately, this domain is not available or it doesn't sell guest posts. Try another domain - for example like 'betimate.com'",
                3500,
              );
            }
            setDataLoading(false);
            refreshBalance();
            if (tableRef.current) {
              tableRef.current.scrollLeft = 0;
            }
          }
        });
    } else if (domainName) {
      const queryParams = new URLSearchParams(location.search);
      const domain = queryParams.get('domain');
      const checkSearchLimit = domain
        ? false
        : Number(currentBalance) <= 0
        ? true
        : false;
      await get(
        `/api/domains/exact-search?userId=${userId}&query=${domainName}&checkSearchLimit=${checkSearchLimit}`,
      )
        .then((res) => {
          if (res.status === 200) {
            return res.json();
          } else if (res.status === 429) {
            toastError(
              'You have reached the free tier limit of 10 searches per day, please subscribe to a plan to continue.',
              3500,
            );
            return null;
          }
        })
        .then((data) => {
          if (data) {
            const encryptedData = data.data;
            const bytes = CryptoJS.AES.decrypt(
              encryptedData,
              process.env.REACT_APP_CRYPTO_SECRET,
            );
            const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

            if (!decryptedData) {
              return;
            }
            setData(decryptedData);
            setTotalDataCount(data.total);
            if (data.total === 0) {
              toastError(
                "Unfortunately, this domain is not available or it doesn't sell guest posts. Try another domain - for example like 'betimate.com'",
                3500,
              );
            }
            setDataLoading(false);
            refreshBalance();
            if (tableRef.current) {
              tableRef.current.scrollLeft = 0;
            }
          }
        });
    } else {
      setData([]);
      setTotalDataCount(0);
      setDataLoading(false);
      setCurrentPage(1);
    }
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [pageSize]);

  useEffect(() => {
    const handleSearchWrapper = async () => {
      if (domainName) {
        await handleSearch({ preventDefault: () => {} });
      } else {
        await handleSearch();
      }
    };

    handleSearchWrapper();
  }, [currentPage, pageSize, domainName]);

  const handleCategoryChange = (selectedOption) => {
    setCategories(selectedOption);
  };

  const handleCountryChange = (selectedOption) => {
    if (selectedOption.length > 0) {
      setCountries(selectedOption);
    } else {
      setCountries(null);
    }
  };

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize },
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
  );

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    tableInstance;

  const nextPage = () => {
    if (currentPage < Math.ceil(totalDataCount / pageSize)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <>
      <InfoModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        template={'filters'}
      />
      <h2 className="mt-8 text-center text-2xl font-bold">Search by Domain</h2>
      <div className="container mx-auto mt-8 flex h-full min-h-[100vh] flex-col px-4 md:flex-row md:justify-between">
        <div className="md:w-[21%]">
          <h2 className="mb-4 text-2xl font-bold">
            Filters{' '}
            <MdInfo
              className="ml-2 inline h-5 w-5 cursor-pointer text-brand-500"
              onClick={() => setIsModalOpen(true)}
            />
          </h2>
          <div className="text-lg font-bold">Allowed Niches</div>
          <div className="mt-1 w-full">
            <Select
              options={nichesOptions}
              value={allowedNiche}
              onChange={(selectedOption) => setAllowedNiche(selectedOption)}
              placeholder="Select allowed niche"
              styles={styles}
            />
          </div>
          {/* <div className="mt-4 text-lg font-bold">Categories</div>
          <div className="mt-1 w-full">
            <Select
              isMulti
              options={categoryOptions}
              value={categories}
              onChange={handleCategoryChange}
              closeMenuOnSelect={false}
              placeholder="Select category"
              styles={styles}
            />
          </div>
          <div className="mt-4 text-lg font-bold">Countries</div>
          <div className="mt-1 w-full">
            <Select
              isMulti
              options={countryOptions.sort((a, b) =>
                a.label.localeCompare(b.label),
              )}
              value={countries}
              onChange={handleCountryChange}
              closeMenuOnSelect={false}
              placeholder="Select country"
              styles={styles}
            />
          </div> */}
        </div>
        <div className="h-full md:w-[77%]">
          {/* Search */}
          <div
            onChange={(e) => {
              const input = e.target.value.trim();
              let domain = '';

              try {
                // Try to parse the input as a URL
                const { hostname } = new URL(
                  input.includes('://') ? input : `http://${input}`,
                );
                domain = hostname.replace('www.', '');
              } catch (error) {
                // If parsing fails, treat the input as a plain domain
                domain = input.replace('www.', '');
              }

              setSearchTerm(domain);
              setHasUserTyped(true);
            }}
            className="mt-5 flex w-full flex-col items-center justify-between rounded-[10px] bg-white shadow-2xl shadow-white md:ml-3 md:mt-0 md:flex-row md:p-[8px] md:pt-[18px]"
          >
            <div className="flex h-9 w-full flex-grow items-center rounded-[10px] bg-lightPrimary text-sm text-gray-600 md:w-auto">
              <FiSearch className="mx-2 h-4 w-4 !text-gray-700" />
              <input
                type="text"
                placeholder="Enter the domain name and press enter or click search"
                className="block h-full w-full rounded-full bg-lightPrimary text-sm text-navy-700 outline-none"
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleSearch(e);
                    setCurrentPage(1);
                  }
                }}
              />
            </div>
            <div className="mt-2 flex w-full items-center md:ml-2 md:mt-0 md:w-auto">
              <button
                className="flex h-9 w-full items-center justify-center rounded-full bg-brand-500 p-4 text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 md:w-auto"
                onClick={(e) => {
                  handleSearch(e);
                  setCurrentPage(1);
                }}
              >
                {dataLoading ? (
                  <>
                    <Spinner width={6} height={6} ml={0} />
                  </>
                ) : (
                  'Search'
                )}
              </button>
            </div>
          </div>
          {/* table */}
          <div
            className="mt-11 h-full w-full overflow-x-scroll xl:overflow-visible"
            ref={tableRef}
          >
            <table {...getTableProps()} className="w-full">
              <thead className="w-full">
                {headerGroups.map((headerGroup, index) => (
                  <tr
                    className=" items-center border-b border-gray-200 dark:!border-white/10"
                    {...headerGroup.getHeaderGroupProps()}
                    key={index}
                  >
                    {headerGroup.headers.map((column, j) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps(),
                          {
                            style: {
                              width: column.width
                                ? `${column.width}px`
                                : 'auto',
                            },
                          },
                        )}
                        className={`${
                          j === 0 || j === 3 || j === 4 || j === 5
                            ? 'min-w-[140px]'
                            : 'min-w-auto'
                        } px-4 pb-[10px] text-start text-xs text-gray-600`}
                      >
                        <TooltipHorizon
                          extra="max-w-[200px] overflow-y-auto"
                          trigger={
                            <p className="cursor-pointer overflow-hidden overflow-ellipsis whitespace-nowrap">
                              {column.render('Header') === 'DOMAIN RATING'
                                ? 'DR'
                                : column.render('Header') === 'ORGANIC TRAFFIC'
                                ? 'OT'
                                : column.render('Header') === 'ORGANIC KEYWORDS'
                                ? 'OK'
                                : column.render('Header') ===
                                  'REFERRING DOMAINS'
                                ? 'RD'
                                : column.render('Header') === 'LINKED DOMAINS'
                                ? 'LD'
                                : column.render('Header')}
                            </p>
                          }
                          content={
                            <p className="text-sm font-bold text-navy-700 dark:text-white">
                              {column.render('Header')}
                            </p>
                          }
                          placement="top"
                        />
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody className="w-full" {...getTableBodyProps()}>
                {page.map((row, index) => {
                  prepareRow(row);
                  return (
                    <React.Fragment key={index}>
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell, index) => {
                          if (cell.column.id === 'expander') {
                            return (
                              <td
                                className="flex items-start px-4 py-[16px]"
                                {...cell.getCellProps({
                                  style: {
                                    width: '50px',
                                  },
                                })}
                                key={index}
                                onClick={(e) =>
                                  row.isExpanded ? null : handleViewMore(e)
                                }
                              >
                                <span
                                  className="flex items-center justify-center"
                                  {...row.getToggleRowExpandedProps({
                                    title: row.isExpanded
                                      ? 'View less'
                                      : 'View more',
                                  })}
                                >
                                  {row.isExpanded ? (
                                    <IoEyeOffOutline className="h-6 w-6" />
                                  ) : (
                                    <IoEyeOutline className="h-6 w-6" />
                                  )}
                                </span>
                              </td>
                            );
                          } else if (cell.column.id === 'price') {
                            return (
                              <td
                                className="mt-[20px] px-4 py-[16px]"
                                {...cell.getCellProps({
                                  style: {
                                    width: cell.column.width
                                      ? `${cell.column.width}px`
                                      : 'auto',
                                  },
                                })}
                                key={index}
                              >
                                Expand to view
                              </td>
                            );
                          } else if (cell.column.id === 'marketplace') {
                            return (
                              <td
                                className="mt-[20px] px-4 py-[16px]"
                                {...cell.getCellProps({
                                  style: {
                                    width: cell.column.width
                                      ? `${cell.column.width}px`
                                      : 'auto',
                                  },
                                })}
                                key={index}
                              >
                                Expand to view
                              </td>
                            );
                          }
                          return (
                            <td
                              className="mt-[20px] px-4 py-[16px]"
                              {...cell.getCellProps({
                                style: {
                                  width: cell.column.width
                                    ? `${cell.column.width}px`
                                    : 'auto',
                                },
                              })}
                              key={index}
                            >
                              {cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>

                      {row.isExpanded && (
                        <tr className="border-b border-t border-gray-200 dark:!border-white/10">
                          <td colSpan={1}>
                            <div className="flex flex-col px-4 py-[16px]">
                              <TooltipHorizon
                                extra="max-w-[200px] overflow-y-auto"
                                trigger={
                                  <p className="overflow-hidden overflow-ellipsis whitespace-nowrap">
                                    OK
                                  </p>
                                }
                                content={
                                  <p className="text-sm font-bold text-navy-700">
                                    Organic Keywords
                                  </p>
                                }
                                placement="top"
                              />
                              <span>
                                {Number(
                                  row.original.orgKeywords,
                                ).toLocaleString()}
                              </span>
                            </div>
                          </td>
                          <td colSpan={1}>
                            <div className="flex flex-col px-4 py-[16px]">
                              <TooltipHorizon
                                extra="max-w-[200px] overflow-y-auto"
                                trigger={
                                  <p className="overflow-hidden overflow-ellipsis whitespace-nowrap">
                                    RD
                                  </p>
                                }
                                content={
                                  <p className="text-sm font-bold text-navy-700">
                                    Referring Domains
                                  </p>
                                }
                                placement="top"
                              />
                              <span>
                                {Number(
                                  row.original.refDomains,
                                ).toLocaleString()}
                              </span>
                            </div>
                          </td>
                          <td colSpan={1}>
                            <div className="flex flex-col px-4 py-[16px]">
                              <TooltipHorizon
                                extra="max-w-[200px] overflow-y-auto"
                                trigger={
                                  <p className="overflow-hidden overflow-ellipsis whitespace-nowrap">
                                    LD
                                  </p>
                                }
                                content={
                                  <p className="text-sm font-bold text-navy-700">
                                    Linked Domains
                                  </p>
                                }
                                placement="top"
                              />
                              <span>
                                {Number(
                                  row.original.linkedDomains,
                                ).toLocaleString()}
                              </span>
                            </div>
                          </td>
                          {/* <td colSpan={1} /> */}
                          <td colSpan={1}>
                            <div className="px-4 py-[16px]">
                              {row.original.marketplaces.map(
                                (marketplace, index) => (
                                  <div key={index}>
                                    <span>€{marketplace.price}</span>
                                  </div>
                                ),
                              )}
                            </div>
                          </td>
                          <td colSpan={1}>
                            <div className="px-4 py-[16px]">
                              {row.original.marketplaces.map(
                                (marketplace, index) => {
                                  const referralUrl =
                                    referralUrls[marketplace.name] ||
                                    marketplace.name;
                                  return (
                                    <div key={index}>
                                      <a
                                        href={`https://${referralUrl}`}
                                        target="_blank"
                                        rel="noreferrer"
                                        className="cursor-pointer text-blue-500 hover:underline"
                                      >
                                        {marketplace.name}
                                      </a>
                                    </div>
                                  );
                                },
                              )}
                            </div>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
          {/* pagination */}
          <div className="mt-2 flex h-[80px] w-full items-center justify-between md:px-6">
            {/* left side */}
            <div className="flex items-center gap-2">
              <select
                value={pageSize}
                onChange={(e) => setPageSize(Number(e.target.value))}
                className="h-10 w-[70px] rounded-xl border border-gray-200 px-2 text-sm  text-gray-600 dark:!border-white/10 dark:!bg-navy-800"
                name=""
                id=""
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
                <option value="50">50</option>
              </select>
              <p className="text-sm text-gray-700">
                Showing {page.length} of {totalDataCount.toLocaleString()}
              </p>
            </div>
            {/* right side */}
            <div className="flex items-center gap-2">
              <button
                onClick={() => previousPage()}
                className={`linear flex items-center justify-center rounded-full bg-brand-500 p-2 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
              >
                <MdChevronLeft />
              </button>

              <p className="text-sm text-gray-700">
                {totalDataCount === 0
                  ? '1 of 1'
                  : `${currentPage} of ${Math.ceil(
                      totalDataCount / pageSize,
                    ).toLocaleString()}`}
              </p>

              <button
                onClick={() => nextPage()}
                className={`linear flex items-center justify-center rounded-full bg-brand-500 p-2 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
              >
                <MdChevronRight />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SearchByDomain;
